var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutWithoutMap',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Informations générales sur les risques dans la commune ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{class:[
        !_vm.risque.data || !_vm.risque.data.risqueDetail ? 'm-auto mt-28' : '' ]},[(!_vm.risque.data || !_vm.risque.data.risqueDetail)?[_c('div',{staticClass:"flex justify-center items-center h-full"},[_c('empty-state',{staticClass:"h-4/5 mt-7 bg-transparent",attrs:{"message":'Informations des risques non disponibles.',"main_icon":'bot1',"classes_icon":"!w-1/4 !h-1/4 sm:!w-48 sm:!h-48","message_classes":"!w-full"}})],1)]:[_c('ResumerRisques',{attrs:{"risques":_vm.risque.data}}),_c('CatastrophesNaturelles',{attrs:{"risques":_vm.risque.data}}),(
            _vm.risque.data.definitions_risques &&
            _vm.risque.data.tri &&
            _vm.risque.data.papi &&
            _vm.risque.data.azi &&
            _vm.risque.data.statut_zone_sismicite &&
            _vm.risque.data.radon &&
            _vm.risque.data.catnat &&
            _vm.details_risques.length
          )?_c('div',{staticClass:"mt-12"},[_c('p',{staticClass:"text-promy-purple-400 font-extrabold text-xl font-main sm:text-base mb-10 sm-ws:mb-6",attrs:{"id":"section-detais-risques"}},[_vm._v(" Détails des risques dans la commune ")]),_c('Accordion',{staticClass:"w-full accordion-ipad"},_vm._l((_vm.details_risques),function(item,index){return _c('AccordionItem',{key:index,staticClass:"shadow-box bg-white mb-4 rounded-lg",attrs:{"id":("accordion-item-" + (item.key))}},[(item.name != 'Risques en montagne')?_c('template',{slot:"accordion-trigger"},[_c('a',{staticClass:"flex justify-between items-center sm:items-start w-full font-main py-6 px-6 cursor-pointer rounded-lg",attrs:{"id":item.key},on:{"click":function($event){return _vm.activingAccordionInfo(index)}}},[_c('div',{staticClass:"w-full"},[_c('div',{staticClass:"flex justify-start items-center space-x-3 mb-4"},[_c(_vm.nameComponent(item.key, 'Icon'),{tag:"component"}),_c('span',{staticClass:"font-bold sm:text-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])],1),_vm._l((item.sub_title),function(sub,idx){return _c('span',{key:idx,staticClass:"sm:text-sm"},[_vm._v(" "+_vm._s(sub.label.charAt(0).toUpperCase() + sub.label.slice(1))+" "),(sub.displayRayon)?_c('span',[_vm._v(" "+_vm._s(sub.rayon)+"m")]):_vm._e(),_vm._v(" : "),_c('span',{staticClass:"font-bold capitalize"},[_vm._v(_vm._s(sub.value))]),_c('br')])})],2),_c('div',{staticClass:"sm:mt-2"},[(_vm.active_accordion_info != index)?_c('ProIcon',{attrs:{"name":"downarrow"}}):_c('ProIcon',{attrs:{"name":"uparrow"}})],1)])]):_vm._e(),(item.name != 'Risques en montagne')?_c('template',{slot:"accordion-content"},[_c('div',{staticClass:"p-6 px-6 border-t-2 cursor-auto"},[_c(_vm.nameComponent(item.key),{tag:"component",attrs:{"risque":item,"active":_vm.accordionIsActive(index)}})],1)]):_vm._e()],2)}),1)],1):_vm._e()]],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }