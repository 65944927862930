<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p
        v-if="sub.key === 'ppr' && sub.value != 'Non'"
        class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4"
      >
        Réglementations
      </p>
      <p
        v-if="sub.value === 'Oui'"
        class="text-promy-gray-600 font-arial sm:text-sm py-4"
        :class="{ 'font-bold': sub.key != 'ppr' }"
      >
        {{ sub.descr }}
      </p>
      <div
        v-if="sub.value === 'Oui'"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            v-if="active"
            :id_map="`map_cav_${idx}`"
            :layer="sub.key === 'ppr' ? 'PPRN_COMMUNE_RISQCAV' : sub.layer"
            :geo_value="sub.key === 'ppr' ? 'georisques' : 'geoservices'"
            :rayon="sub.rayon"
            ref="map"
          />
        </div>
        <img
          v-if="sub.key === 'ppr'"
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=PPRN_COMMUNE_RISQCAV`"
          class="ml-6 md:ml-0 md:mt-4"
        />
        <img
          v-else
          :src="`http://geoservices.brgm.fr/risques?language=fre&version=1.1.1&service=WMS&request=GetLegendGraphic&layer=${sub.layer}&format=image/png&STYLE=inspire_common:DEFAULT`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>

      <div v-if="sub.key === 'ppr' && sub.value != 'Non'" class="w-full">
        <p
          class="
            text-promy-gray-700
            font-arial font-bold
            text-lg
            sm:text-sm
            pt-6
            pb-4
          "
        >
          Arrêtés
        </p>
        <TablesRisques :keyTable="sub.key" :body="sub.bodyTable" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'
import TablesRisques from './TablesRisques'
export default {
  mixins: [risques, helpers],

  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  components: {
    Map,
    TablesRisques,
  },

  data() {
    return {
      pprn_info: [],
    }
  },

  created() {
    this.risque.sub_title.forEach((sub) => {
      if (sub.key === 'alea') {
        axios
          .get(
            `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&propertyname=${sub.properties}&X=${this.lat}&Y=${this.long}&rayon=${sub.rayon}&resultType=results`,
          )
          .then(
            (response) => {
              let data_json = this.xmlToJson(response.data)
              sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
                'gml:null'
              ]
                ? 'Informations non connues'
                : 'Oui'
              sub.displayRayon = true
            },
            (error) => {
              console.log(error)
            },
          )
      }

      if (sub.key === 'ppr') {
        this.prepareDataPPR(sub, '12')
      }
    })
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
      long: 'address/long',
      lat: 'address/lat',
    }),
  },
}
</script>

<style lang="scss" scoped></style>
