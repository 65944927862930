<template>
  <LayoutWithoutMap>
    <template #title>
      Informations générales sur les risques dans la commune
    </template>
    <template #body>
      <div
        :class="[
          !risque.data || !risque.data.risqueDetail ? 'm-auto mt-28' : '',
        ]"
      >
        <template v-if="!risque.data || !risque.data.risqueDetail">
          <div class="flex justify-center items-center h-full">
            <empty-state
              class="h-4/5 mt-7 bg-transparent"
              :message="'Informations des risques non disponibles.'"
              :main_icon="'bot1'"
              classes_icon="!w-1/4 !h-1/4 sm:!w-48 sm:!h-48"
              message_classes="!w-full"
            />
          </div>
        </template>
        <template v-else>
          <ResumerRisques :risques="risque.data" />
          <CatastrophesNaturelles :risques="risque.data" />
          <!-- other risques -->
          <!-- this below section needs to be styled and add details to each risque -->
          <div
            class="mt-12"
            v-if="
              risque.data.definitions_risques &&
              risque.data.tri &&
              risque.data.papi &&
              risque.data.azi &&
              risque.data.statut_zone_sismicite &&
              risque.data.radon &&
              risque.data.catnat &&
              details_risques.length
            "
          >
            <p
              class="text-promy-purple-400 font-extrabold text-xl font-main sm:text-base mb-10 sm-ws:mb-6"
              id="section-detais-risques"
            >
              Détails des risques dans la commune
            </p>
            <Accordion class="w-full accordion-ipad">
              <AccordionItem
                class="shadow-box bg-white mb-4 rounded-lg"
                v-for="(item, index) in details_risques"
                :id="`accordion-item-${item.key}`"
                :key="index"
              >
                <template
                  slot="accordion-trigger"
                  v-if="item.name != 'Risques en montagne'"
                >
                  <a
                    class="flex justify-between items-center sm:items-start w-full font-main py-6 px-6 cursor-pointer rounded-lg"
                    :id="item.key"
                    @click="activingAccordionInfo(index)"
                  >
                    <div class="w-full">
                      <div
                        class="flex justify-start items-center space-x-3 mb-4"
                      >
                        <component :is="nameComponent(item.key, 'Icon')" />
                        <span class="font-bold sm:text-sm">
                          {{ item.name }}
                        </span>
                      </div>
                      <span
                        class="sm:text-sm"
                        v-for="(sub, idx) in item.sub_title"
                        :key="idx"
                      >
                        {{
                          sub.label.charAt(0).toUpperCase() + sub.label.slice(1)
                        }}
                        <span v-if="sub.displayRayon"> {{ sub.rayon }}m</span>
                        :
                        <span class="font-bold capitalize">{{ sub.value }}</span
                        ><br />
                      </span>
                    </div>
                    <div class="sm:mt-2">
                      <ProIcon
                        name="downarrow"
                        v-if="active_accordion_info != index"
                      />
                      <ProIcon name="uparrow" v-else />
                    </div>
                  </a>
                </template>
                <template
                  slot="accordion-content"
                  v-if="item.name != 'Risques en montagne'"
                >
                  <div class="p-6 px-6 border-t-2 cursor-auto">
                    <component
                      :is="nameComponent(item.key)"
                      :risque="item"
                      :active="accordionIsActive(index)"
                    />
                  </div>
                </template>
              </AccordionItem>
            </Accordion>
          </div>
        </template>
      </div>
    </template>
  </LayoutWithoutMap>
</template>
<script>
import RisqueNumber from './components/RisqueNumber.vue'
import ExpositionScore from './components/ExpositionScore.vue'
import PresentRisque from './components/PresentRisque.vue'
import ResumerRisques from './ResumerRisques.vue'
import CatastrophesNaturelles from './components/CatastrophesNaturelles.vue'
import icons from './icons'
import LayoutWithoutMap from '../LayoutWithoutMap'
import { mapGetters } from 'vuex'
import risques from '@/mixins/risques'
import Can from './Details/Can.vue'
import Cav from './Details/Cav.vue'
import Inn from './Details/Inn.vue'
import Inst from './Details/Inst.vue'
import Mvmt from './Details/Mvmt.vue'
import Nuc from './Details/Nuc.vue'
import Pol from './Details/Pol.vue'
import Radon from './Details/Radon.vue'
import Retrait from './Details/Retrait.vue'
import Seism from './Details/Seism.vue'

export default {
  mixins: [risques],
  components: {
    RisqueNumber,
    ExpositionScore,
    PresentRisque,
    ResumerRisques,
    CatastrophesNaturelles,
    LayoutWithoutMap,
    ...icons,
    Can,
    Cav,
    Inn,
    Inst,
    Mvmt,
    Nuc,
    Pol,
    Radon,
    Retrait,
    Seism,
  },
  data() {
    return {
      active_accordion: {
        _value: -1,
        is_active: (idx) => this.active_accordion._value === idx,
        set_active_accordion: (idx) => {
          this.active_accordion._value = idx
        },
        get_active_accordion: () => this.active_accordion._value,
      },
      active_accordion: -1,
      active_accordion_info: -1,
    }
  },
  computed: {
    ...mapGetters({
      details_risques: 'address/getDetailsRisques',
      risque: 'address/getRisque',
    }),
  },
  created() {
    this.setRisquesByAdress(this.risque.data)
  },
  methods: {
    activingAccordion(idx) {
      this.active_accordion = this.active_accordion === idx ? -1 : idx
    },
    activingAccordionInfo(idx) {
      this.active_accordion_info = this.active_accordion_info === idx ? -1 : idx
    },
    accordionIsActive(idx) {
      return this.active_accordion_info === idx
    },
    HandleDetails() {
      alert('Details')
    },
    nameComponent(name, prefix = '') {
      return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase() + prefix
    },
  },
}
</script>
