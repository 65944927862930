<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4"
      >
        {{ sub.descr }}
      </p>
      <div
        v-if="sub.value != 'Non'"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            v-if="active"
            ref="map"
            domaine_wms="carmencarto"
            :id_map="`map_seismes_${idx}`"
            :layer="
              sub.layer === 'Zonage_sismique_Communes'
                ? 'Zonage_sismique_Communes'
                : 'PPRN_COMMUNE_RISQSEIS'
            "
            :geo_value="sub.key === 'ppr' ? 'georisques' : 'geoservices'"
          />
        </div>
        <img
          v-if="sub.key === 'ppr'"
          :src="`https://mapsref.brgm.fr/wxs/georisques/risques?version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=PPRN_COMMUNE_RISQSEIS`"
          class="ml-6 md:ml-0 md:mt-4"
        />
        <img
          v-else
          :src="`http://ws.carmencarto.fr/WMS/168/zonage_sismique?&service=WMS&version=1.3.0&request=GetLegendGraphic&format=image/png&sld_version=1.1.0&layer=${sub.layer}`"
          class="ml-6 md:ml-0 md:mt-4"
        />
      </div>
      <div v-if="sub.key === 'ppr' && sub.value != 'Non'" class="w-full">
        <p
          class="
            text-promy-gray-700
            font-arial font-bold
            text-lg
            sm:text-sm
            pt-6
            pb-4
          "
        >
          Arrêtés
        </p>
        <TablesRisques :keyTable="sub.key" :body="sub.bodyTable" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'
import TablesRisques from './TablesRisques'

export default {
  mixins: [risques, helpers],

  components: {
    Map,
    TablesRisques,
  },
  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  data() {
    return {
      pprn_info: [],
    }
  },

  created() {
    this.risque.sub_title = []
    this.setDetailRisque()
    this.risque.sub_title.forEach((sub) => {
      if (sub.key === 'alea')
        sub.value = this.notEmptyObject(this.risques.statut_zone_sismicite.data)
          ? this.risques.statut_zone_sismicite.data[0].zone_sismicite.toLowerCase()
          : 'Non'

      if (sub.key === 'ppr') {
        this.prepareDataPPR(sub, '13')
      }
    })
  },

  methods: {
    setDetailRisque() {
      this.risques.definitions_risques.SEISM.alea.forEach((inst) => {
        if (inst.id === 'SEISM') this.setObj('alea', inst)
      })
      this.setObj('ppr', this.risques.definitions_risques.SEISM.ppr)
    },

    setObj(key, obj) {
      let alea_obj = {
        key: key,
        title: obj.title,
        label: obj.label,
        rayon: obj.rayon,
        layer: obj.layer[0],
        properties: obj.properties.toString(),
        value: '',
        descr: obj.descr,
        codes: key === 'ppr' ? obj.codes : [],
      }
      this.risque.sub_title.push(alea_obj)
    },
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
    }),
  },
}
</script>

<style lang="scss" scoped>
thead {
  tr {
    @apply rounded-t-lg;
  }
}
th,
td {
  @apply py-2 px-3;
}

tbody tr:nth-child(even) {
  @apply bg-promy-gray-100;
}
</style>
