<template>
  <div>
    <p class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4">
      {{ risque.name }}
    </p>
    <p
      v-for="(definition, index) in risque.definitions"
      :key="index"
      class="text-promy-gray-600 font-arial sm:text-sm"
    >
      {{ definition }}
    </p>
    <div v-for="(sub, idx) in risque.sub_title" :key="'sub' + idx" class="py-6">
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-700 font-arial font-bold text-lg sm:text-sm py-4"
      >
        {{ sub.title }}
      </p>
      <p
        v-if="sub.value != 'Non'"
        class="text-promy-gray-600 font-arial font-bold sm:text-sm py-4"
      >
        {{ sub.descr }}
      </p>
      <div
        v-if="sub.value != 'Non' && sub.value != ''"
        class="w-full flex md:flex-col justify-start items-start"
      >
        <div
          class="w-2/5 md:w-full h-64 border-2 flex justify-center items-center"
        >
          <Map
            v-if="active"
            :id_map="`map_pol_${idx}`"
            :layer="sub.layer"
            :rayon="sub.rayon"
            geo_value="georisques"
            ref="map"
          />
        </div>
        <div class="flex flex-col">
          <img
            v-if="sub.id === 'BASIAS'"
            :src="`${sub.service}version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=BASIAS_ADRESSE`"
            class="ml-6 md:ml-0 md:mt-4"
          />
          <div
            class="flex space-x-3 items-end ml-6"
            v-else-if="sub.id === 'CASIAS'"
          >
            <img src="/images/marker-blue.svg" class="w-6 h-6 sm:mt-4" />
            <span class="text-xs text-promy-gray-300"
              >Localisation des anciens sites industriels et activités de
              service
            </span>
          </div>

          <img
            v-else
            :src="`${sub.service}version=1.1.1&service=WMS&request=GetLegendGraphic&format=image/png&style=default&layer=${sub.layer}`"
            class="ml-6 md:ml-0 md:mt-4"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import Map from './map.vue'
import risques from '@/mixins/risques'
import helpers from '@/mixins/helpers'

export default {
  mixins: [risques, helpers],

  props: {
    risque: {
      type: Object,
      require: true,
    },
    active: {
      type: Boolean,
      require: true,
    },
  },

  components: {
    Map,
  },

  created() {
    this.risque.definitions = [
      this.risques.definitions_risques.POL.definitionPol +
        this.risques.definitions_risques.POL.definition2Lien +
        this.risques.definitions_risques.POL.definition3,
    ]
    this.risque.sub_title = []
    this.setDetailRisque()

    this.risque.sub_title.forEach((sub) => {
      axios
        .get(
          `https://mapsref.brgm.fr/wxs/georisques/rapport?version=1.0.0&service=wfs&request=getfeature&typename=${sub.layer}&X=${this.lat}&Y=${this.long}&rayon=${sub.rayon}&resultType=results`,
        )
        .then(
          (response) => {
            let data_json = this.xmlToJson(response.data)
            sub.value = data_json['wfs:FeatureCollection']['gml:boundedBy'][
              'gml:null'
            ]
              ? 'Non'
              : Object.keys(
                  data_json['wfs:FeatureCollection']['gml:featureMember'],
                ).length
          },
          (error) => {
            console.log(error)
          },
        )
    })
  },

  methods: {
    setDetailRisque() {
      this.risques.definitions_risques.POL.alea.forEach((pol) => {
        this.setObj('alea', pol)
      })
    },

    setObj(key, obj) {
      let alea_obj = {
        key: key,
        id: obj.id,
        label: obj.label,
        title: obj.title,
        descr: obj.descr,
        rayon: obj.rayon,
        properties: obj.properties,
        layer: obj.id != 'BASIAS' ? obj.layer.join(',') : 'BASIAS_LOCALISE',
        value: '',
        service: obj.service,
        displayRayon: true,
      }
      this.risque.sub_title.push(alea_obj)
    },
  },

  computed: {
    ...mapGetters({
      risques: 'address/risques',
      long: 'address/long',
      lat: 'address/lat',
    }),
  },
}
</script>

<style></style>
